import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="savebar-discard-button"
export default class extends Controller {
  static outlets = ["polaris-form"];

  resetForm() {
    this.polarisFormOutlet.resetForm();
  }
}
