import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";

// Connects to data-controller="ransack-form"
export default class extends Controller {
  initialize() {
    this.submit = debounce(this.submit.bind(this), 400);
  }

  submit() {
    this.element.requestSubmit();
  }

  resetField(name) {
    const fields = Array.from(this.element.querySelectorAll("input")).filter(
      (field) => field.type !== "hidden" && field.name.includes(name),
    );

    fields.forEach((field) => {
      if (field.type === "checkbox") {
        field.checked = false;
      } else {
        field.value = "";
      }
    });
  }
}
