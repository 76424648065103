import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shoelace-color-picker"
// This is for the polaris-form controller to work in order to check if the form is dirty
export default class extends Controller {
  connect() {
    this.element.addEventListener("sl-change", this.onColorChange.bind(this));
  }

  onColorChange(event) {
    const color = event.target.value;
    const colorTextElement = this.element.nextElementSibling;

    colorTextElement.innerHTML = color;
  }

  resetColorText() {
    const colorTextElement = this.element.nextElementSibling;

    colorTextElement.innerHTML = this.element.value;
  }
}
