import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="filter-tags"
export default class extends Controller {
  static outlets = ["ransack-form"];
  static values = {
    associatedField: String,
  };

  resetField() {
    this.ransackFormOutlet.resetField(this.associatedFieldValue);
  }
}
