import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shipping-fee-radio-button"
export default class extends Controller {
  static targets = ["buyerBorneShippingFeeField"];

  connect() {
    this.radioButtonFields.forEach((radioButtonField) =>
      radioButtonField.addEventListener("change", this.toggle.bind(this)),
    );

    this.toggle();
  }

  toggle() {
    this.radioButtonFields.forEach((field) => {
      if (field.checked && field.value === "buyer") {
        this.buyerBorneShippingFeeFieldTarget.classList.remove("hidden");
      } else {
        this.buyerBorneShippingFeeFieldTarget.classList.add("hidden");
      }
    });
  }

  get radioButtonFields() {
    return this.element.querySelectorAll('input[type="radio"]');
  }
}
