import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tag-input"
export default class extends Controller {
  static outlets = ["polaris-form"];
  static targets = ["input", "hiddenInput", "tagList"];

  connect() {
    this.tags = this.hiddenInputTarget.dataset.initialValue
      .split(",")
      .filter((tag) => Boolean(tag));
    this.renderTags();
    this.inputField.dataset.trackFormDirty = "false";
    this.inputField.addEventListener("input", this.input.bind(this));
  }

  input(event) {
    const value = event.target.value.trim();
    if (value.includes(",")) {
      this.addTag(value.slice(0, -1)); // Remove the comma
      this.inputField.value = "";
    }
  }

  resetTagList() {
    this.tags = this.hiddenInputTarget.dataset.initialValue
      .split(",")
      .filter((tag) => Boolean(tag));
    this.renderTags();
    this.updateHiddenInput();
  }

  addTag(tag) {
    if (Boolean(tag) && !this.tags.includes(tag)) {
      this.tags.push(tag);
      this.renderTags();
      this.updateHiddenInput();
      this.polarisFormOutlet.toggleSaveBar();
    }
  }

  removeTag(event) {
    const tagToRemove = event.target.closest(".Polaris-Tag").textContent.trim();
    this.tags = this.tags.filter((tag) => tag !== tagToRemove);
    this.renderTags();
    this.updateHiddenInput();
    this.polarisFormOutlet.toggleSaveBar();
  }

  renderTags() {
    this.tagListTarget.innerHTML = this.tags
      .map(
        (tag) =>
          `<div class="Polaris-Tag" style="margin-right: 5px; margin-bottom: 5px;">
        ${tag}
        <button class="Polaris-Tag__Button" aria-label="Remove" data-action="click->tag-input#removeTag">
          <span class="Polaris-Icon">
            <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
              <path d="M6.707 5.293a1 1 0 0 0-1.414 1.414l3.293 3.293-3.293 3.293a1 1 0 1 0 1.414 1.414l3.293-3.293 3.293 3.293a1 1 0 0 0 1.414-1.414l-3.293-3.293 3.293-3.293a1 1 0 0 0-1.414-1.414l-3.293 3.293-3.293-3.293Z"/>
            </svg>
          </span>
        </button>
      </div>`,
      )
      .join("");
  }

  updateHiddenInput() {
    this.hiddenInputTarget.value = this.tags.join(",");
  }

  get inputField() {
    return this.inputTarget.querySelector("input");
  }
}
