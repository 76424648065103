import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="bonus-store-credit-setting-radio-button"
export default class extends Controller {
  static targets = ["percentageFields", "bonusStoreCreditValueInput"];

  connect() {
    this.radioButtonFields.forEach((radioButtonField) =>
      radioButtonField.addEventListener("change", this.toggle.bind(this)),
    );

    this.toggle();
  }

  toggle() {
    this.clearPrefixSuffix();

    this.radioButtonFields.forEach((field) => {
      if (field.checked) {
        if (field.value === "percentage") {
          this.showPercentageFields();
          this.addPercentageSuffix();
        } else if (field.value === "fixed_amount") {
          this.hidePercentageFields();
          this.addCurrencyPrefix();
        }
      } else {
        if (field.value === "percentage") {
          this.hidePercentageFields();
        }
      }
    });
  }

  clearPrefixSuffix() {
    const wrapper = this.element;
    wrapper
      .querySelectorAll("#currency-prefix, #percentage-suffix")
      .forEach((el) => el.remove());
  }

  addCurrencyPrefix() {
    const prefixDiv = document.createElement("div");
    const bonusStoreCreditValueInput = this.bonusStoreCreditValueInputTarget;
    const inputTarget = bonusStoreCreditValueInput.querySelector("input");
    const { currency } = bonusStoreCreditValueInput.dataset;
    prefixDiv.textContent = currency;
    prefixDiv.classList.add("Polaris-TextField__Prefix");
    prefixDiv.id = "currency-prefix";
    bonusStoreCreditValueInput.insertBefore(prefixDiv, inputTarget);
  }

  addPercentageSuffix() {
    const suffixDiv = document.createElement("div");
    const bonusStoreCreditValueInput = this.bonusStoreCreditValueInputTarget;
    const inputTarget = bonusStoreCreditValueInput.querySelector("input");
    suffixDiv.textContent = "%";
    suffixDiv.classList.add("Polaris-TextField__Suffix");
    suffixDiv.id = "percentage-suffix";
    inputTarget.insertAdjacentElement("afterend", suffixDiv);
  }

  showPercentageFields() {
    this.percentageFieldsTarget.classList.add("flex");
    this.percentageFieldsTarget.classList.remove("hidden");
  }

  hidePercentageFields() {
    this.percentageFieldsTarget.classList.add("hidden");
    this.percentageFieldsTarget.classList.remove("flex");
  }

  get radioButtonFields() {
    return this.element.querySelectorAll('input[type="radio"]');
  }
}
