import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shoelace-carousel"
export default class extends Controller {
  goToSlide(event) {
    this.carouselElement.goToSlide(event.params.index);
  }

  get carouselElement() {
    return this.element.querySelector("sl-carousel");
  }
}
