// Turbo
import * as Turbo from "@hotwired/turbo";
Turbo.start();

Turbo.StreamActions.redirect = function () {
  const url = this.getAttribute("url");

  window.location.replace(url);
};

// Stimulus
import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";
const application = Application.start();

application.debug = false;
window.Stimulus = application;

const controllers = import.meta.glob(
  "../../javascript/controllers/**/*_controller.js",
  { eager: true },
);
registerControllers(application, controllers);

// Active Storage
import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

// Polaris View Components
import { registerPolarisControllers } from "polaris-view-components";
registerPolarisControllers(Stimulus);

// Shoelace
import "@shoelace-style/shoelace/dist/components/color-picker/color-picker.js";
import "@shoelace-style/shoelace/dist/components/carousel/carousel.js";
import "@shoelace-style/shoelace/dist/components/carousel-item/carousel-item.js";
