import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="polaris-form"
export default class extends Controller {
  static outlets = [
    "polaris-frame",
    "polaris-button",
    "polaris-select",
    "polaris-dropzone",
    "shoelace-color-picker",
    "remove-association",
    "checkbox-with-toggleable-fields",
    "new-shipping-method-form",
    "bonus-store-credit-setting-radio-button",
    "shipping-fee-radio-button",
    "tag-input",
  ];

  initialize() {
    this.resetForm();
  }

  connect() {
    this.initialValues = {};
    this.polarisFormFields.forEach((field) => {
      field.addEventListener("input", this.toggleSaveBar.bind(this));
      if (field.type === "checkbox") {
        this.initialValues[field.name] = field.checked;
      } else if (field.type === "radio") {
        if (field.checked) {
          this.initialValues[field.name] = field.value;
        }
      } else {
        this.initialValues[field.name] = field.value;
      }
    });
    this.shoelaceFormFields.forEach((field) => {
      field.addEventListener("sl-change", this.toggleSaveBar.bind(this));
      this.initialValues[field.name] = field.value;
    });
    this.element.addEventListener(
      "turbo:submit-start",
      this.onFormSubmitStart.bind(this),
    );
  }

  polarisDropzoneOutletConnected(_outlet, element) {
    element.addEventListener(
      "polaris-dropzone:drop",
      this.toggleSaveBar.bind(this),
    );
  }

  toggleSaveBar() {
    if (this.isFormChanged()) {
      this.polarisFrameOutlet.showSaveBar();
      this.polarisButtonOutlets.forEach((outlet) => outlet.enable());
    } else {
      this.polarisFrameOutlet.hideSaveBar();
    }
  }

  onFormSubmitStart(event) {
    this.polarisButtonOutlets.forEach((outlet) => {
      if (outlet.element.type === "submit") {
        outlet.disable(event);
      } else {
        outlet.disableWithoutLoader(event);
      }
    });
  }

  resetForm() {
    this.element.reset();
    this.checkboxWithToggleableFieldsOutlets.forEach((outlet) =>
      outlet.toggle(),
    );
    this.bonusStoreCreditSettingRadioButtonOutlets.forEach((outlet) => {
      outlet.toggle();
    });
    this.shippingFeeRadioButtonOutlets.forEach((outlet) => {
      outlet.toggle();
    });
    this.polarisSelectOutlets.forEach((outlet) => outlet.update());
    this.shoelaceColorPickerOutlets.forEach((outlet) =>
      outlet.resetColorText(),
    );
    this.newShippingMethodFormOutlets.forEach((outlet) =>
      outlet.resetSelectFields(),
    );
    this.polarisDropzoneOutlets.forEach((outlet) => {
      outlet.removePreview();
      outlet.toggleFileUpload(true);
    });
    this.polarisFrameOutlet.hideSaveBar();
    this.removeAssociationOutlets.forEach((outlet) => {
      outlet.reset();
    });
    this.tagInputOutlets.forEach((outlet) => {
      outlet.resetTagList();
    });
  }

  isFormChanged() {
    return Array.from(this.formFields).some((field) => {
      if (field.type === "checkbox") {
        return field.checked !== this.initialValues[field.name];
      } else if (field.type === "radio") {
        if (field.checked) {
          return field.value !== this.initialValues[field.name];
        } else {
          return false;
        }
      } else {
        return field.value !== this.initialValues[field.name];
      }
    });
  }

  get formFields() {
    return [...this.polarisFormFields, ...this.shoelaceFormFields];
  }

  get polarisFormFields() {
    return Array.from(
      this.element.querySelectorAll("input, textarea, select"),
    ).filter((field) => {
      return (
        (field.type !== "hidden" && field.dataset.trackFormDirty !== "false") ||
        field.name.includes("_destroy") ||
        field.dataset.trackFormDirty === "true"
      );
    });
  }

  get shoelaceFormFields() {
    return this.shoelaceColorPickerOutlets.map((outlet) => outlet.element);
  }
}
