import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="remove-association"
export default class extends Controller {
  static targets = ["hiddenField"];

  remove(event) {
    event.preventDefault();

    this.element.style.display = "none";

    this.hiddenFieldTarget.value = 1;

    // Dispatch the input event
    const inputEvent = new Event("input", {
      bubbles: true,
      cancelable: true,
    });
    this.hiddenFieldTarget.dispatchEvent(inputEvent);
  }

  reset() {
    if (this.hiddenFieldTarget.value === "1") {
      this.hiddenFieldTarget.value = "false";
    }

    if (this.element.style.display === "none") {
      this.element.style.display = "block";
    }
  }
}
