import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { put } from "@rails/request.js";

// Connects to data-controller="drag-and-sort"
export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 200,
      onEnd: this.end.bind(this),
    });
  }

  end(event) {
    const { moveUrl } = event.item.dataset;

    put(moveUrl, {
      body: { position: event.newIndex + 1 },
      responseKind: "turbo-stream",
    });
  }
}
