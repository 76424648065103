import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="checkbox-with-toggleable-fields"
export default class extends Controller {
  static targets = ["toggleableFields", "checkboxField"];

  connect() {
    this.checkboxField.addEventListener("change", this.toggle.bind(this));

    this.toggle();
  }

  show() {
    this.toggleableFieldsTarget.classList.remove("hidden");
    this.toggleableFieldsTarget.classList.add("flex");
  }

  hide() {
    this.toggleableFieldsTarget.classList.add("hidden");
    this.toggleableFieldsTarget.classList.remove("flex");
  }

  toggle() {
    if (this.checkboxField.checked) {
      this.show();
    } else {
      this.hide();
    }
  }

  get checkboxField() {
    return this.checkboxFieldTarget.querySelector('input[type="checkbox"]');
  }
}
