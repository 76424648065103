import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="new-shipping-method-form"
export default class extends Controller {
  static outlets = ["polaris-select"];
  static targets = [
    "carrierSelect",
    "accountOwnerSelect",
    "registrationFields",
  ];

  countryChange(event) {
    let countryId = event.target.selectedOptions[0].value;
    let target = this.carrierSelectField.id;
    this.carrierSelectField.value = "";
    this.accountOwnerSelectField.value = "";
    this.resetRegistrationFields();

    get(
      `/retailer/shipping_method_registrations/shipping_services?target=${target}&country_id=${countryId}`,
      {
        responseKind: "turbo-stream",
      },
    );
    this.polarisSelectOutlets.forEach((outlet) => outlet.update());
  }

  shippingServiceChange(event) {
    let shippingServiceId = event.target.selectedOptions[0].value;
    let target = this.accountOwnerSelectField.id;
    this.accountOwnerSelectField.value = "";
    this.resetRegistrationFields();

    get(
      `/retailer/shipping_method_registrations/account_owners?target=${target}&shipping_service_id=${shippingServiceId}`,
      {
        responseKind: "turbo-stream",
      },
    );
    this.polarisSelectOutlets.forEach((outlet) => outlet.update());
  }

  accountOwnerChange(event) {
    const accountOwner = event.target.selectedOptions[0].value;
    const target = this.registrationFieldsTarget.id;
    const shippingServiceId = this.carrierSelectField.selectedOptions[0].value;
    const queryParams = `target=${target}&account_owner=${accountOwner}&shipping_service_id=${shippingServiceId}`;

    get(
      `/retailer/shipping_method_registrations/registration_fields?${queryParams}`,
      {
        responseKind: "turbo-stream",
      },
    );
  }

  resetSelectFields() {
    this.resetCarrierSelectField();
    this.resetAccountOwnerSelectField();
    this.resetRegistrationFields();
  }

  resetCarrierSelectField() {
    Array.from(this.carrierSelectField.options).forEach((option) => {
      if (option.value !== "") {
        option.remove();
      }
    });
  }

  resetAccountOwnerSelectField() {
    Array.from(this.accountOwnerSelectField.options).forEach((option) => {
      if (option.value !== "") {
        option.remove();
      }
    });
  }

  resetRegistrationFields() {
    while (this.registrationFieldsTarget.firstChild) {
      this.registrationFieldsTarget.removeChild(
        this.registrationFieldsTarget.firstChild,
      );
    }
  }

  get carrierSelectField() {
    return this.carrierSelectTarget.querySelector("select");
  }

  get accountOwnerSelectField() {
    return this.accountOwnerSelectTarget.querySelector("select");
  }
}
