import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="admin-polaris-form"
export default class extends Controller {
  static outlets = ["polaris-button", "polaris-select"];

  initialize() {
    this.resetForm();
  }

  connect() {
    this.initialValues = {};
    this.polarisFormFields.forEach((field) => {
      if (field.type === "checkbox") {
        this.initialValues[field.name] = field.checked;
      } else {
        this.initialValues[field.name] = field.value;
      }
    });
    this.element.addEventListener(
      "turbo:submit-start",
      this.onFormSubmitStart.bind(this),
    );
  }

  onFormSubmitStart(event) {
    this.polarisButtonOutlets.forEach((outlet) => {
      if (outlet.element.type === "submit") {
        outlet.disable(event);
      } else {
        outlet.disableWithoutLoader(event);
      }
    });
  }

  resetForm() {
    this.element.reset();
    this.polarisSelectOutlets.forEach((outlet) => outlet.update());
  }

  isFormChanged() {
    return Array.from(this.formFields).some((field) => {
      if (field.type === "checkbox") {
        return field.checked !== this.initialValues[field.name];
      } else {
        return field.value !== this.initialValues[field.name];
      }
    });
  }

  get formFields() {
    return [...this.polarisFormFields];
  }

  get polarisFormFields() {
    return Array.from(
      this.element.querySelectorAll("input, textarea, select"),
    ).filter(
      (field) => field.type !== "hidden" || field.name.includes("_destroy"),
    );
  }
}
